var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-filters text-right"},[_c('span',[_vm._v(_vm._s(_vm.$t("labels.filterBy")))]),_c('div',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"250px"}},[_c('date-range-picker',{attrs:{"textFieldAttrs":{
          solo: true,
          placeholder: _vm.$t('labels.date'),
        },"on":{
          change: _vm.fetchData,
        }},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1),_c('v-select',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"180px"},attrs:{"items":_vm.types,"placeholder":_vm.$t('feedback.type'),"clearable":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}}),_c('v-select',{staticClass:"d-inline-block ml-5",staticStyle:{"width":"170px"},attrs:{"items":_vm.statuses,"placeholder":_vm.$t('labels.status'),"clearable":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('v-checkbox',{staticClass:"d-inline-block ml-5",attrs:{"label":_vm.$t('feedback.showArchived'),"hide-details":""},on:{"change":_vm.fetchData},model:{value:(_vm.filters.show_archived),callback:function ($$v) {_vm.$set(_vm.filters, "show_archived", $$v)},expression:"filters.show_archived"}})],1),_c('v-data-table',{staticClass:"feedback-table",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['feedback/items'],"items-per-page":_vm.per_page,"page":_vm.page,"loading":_vm.$store.getters['feedback/loading'].fetchAll,"server-items-length":_vm.$store.getters['feedback/total'],"footer-props":_vm.$config.vDataTable.footerProps,"show-expand":"","single-expand":"","disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.per_page=$event},"update:items-per-page":[function($event){_vm.per_page=$event},_vm.fetchData],"update:page":[function($event){_vm.page=$event},_vm.fetchData]},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"expanded",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-between"},[_vm._v(" "+_vm._s(item.user ? _vm.$fullName(item.user) : _vm.$t("feedback.anonymous"))+" • "+_vm._s(_vm.$formatDatetime(item.created_at))+" ")]),_c('div',{staticClass:"formatted-text py-1"},[_vm._v(_vm._s(item.content))])])]}},{key:"item.user",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.$fullName(value) : _vm.$t("feedback.anonymous"))+" ")]}},{key:"item.type",fn:function(ref){
        var value = ref.value;
return [_c('FeedbackType',{attrs:{"value":value}})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{class:'status-' + item.status,attrs:{"items":_vm.statuses,"loading":_vm.$store.getters['orders/loading'].update == item.id,"hide-details":"","solo":""},on:{"change":function($event){return _vm.updateStatus(item, $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.created_at",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatDate(value))+" ")]}},{key:"item.archive-button",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","color":"transparent"},on:{"click":function($event){return _vm.archive(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.is_archived ? "mdi-undo-variant" : "mdi-archive-outline")+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }